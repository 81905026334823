const tx = document.getElementsByTagName('textarea')
for (let i = 0; i < tx.length; i++) {
  if (tx[i].value !== '') {
    tx[i].setAttribute('style', 'height:' + (tx[i].scrollHeight + 10) + 'px;')
  }
  tx[i].addEventListener('input', OnInput, false)
}
window.addEventListener('resize', function () {
  for (let i = 0; i < tx.length; i++) {
    if (tx[i].value !== '') {
      tx[i].setAttribute('style', 'height:auto;')
      tx[i].setAttribute('style', 'height:' + (tx[i].scrollHeight + 10) + 'px;')
    }
  }
}, false)

function OnInput (e) {
  this.style.height = 'auto'
  this.style.height = (this.scrollHeight + 10) + 'px'
}

document.addEventListener('DOMContentLoaded', () => {
  // Get all "navbar-burger" elements
  const navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0)

  // Check if there are any navbar burgers
  if (navbarBurgers.length > 0) {
    // Add a click event on each of them
    navbarBurgers.forEach(el => {
      el.addEventListener('click', () => {
        // Get the target from the "data-target" attribute
        const target = el.dataset.target
        const $target = document.getElementById(target)

        // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
        el.classList.toggle('is-active')
        $target.classList.toggle('is-active')
      })
    })
  }
})

function getTimeSupport () {
  const input = document.createElement('input')
  input.setAttribute('type', 'time')

  const notValid = 'not-a-time'
  input.setAttribute('value', notValid)

  return input.value !== notValid
}

function getDateSupport () {
  const input = document.createElement('input')
  input.setAttribute('type', 'date')

  const notValid = 'not-a-date'
  input.setAttribute('value', notValid)

  return input.value !== notValid
}

function loadJS (src) {
  const script = document.createElement('script')
  script.src = src
  document.head.appendChild(script)
}

document.addEventListener('DOMContentLoaded', function () {
  const supportsTime = getTimeSupport()
  const supportsDate = getDateSupport()
  if (!supportsTime) {
    loadJS('/assets/js/timeInputPolyfill.7c3594eb06032b073ebb.js')
  }
  if (!supportsDate) {
    loadJS('/assets/js/dateInputPolyfill.f882346700dfe3df75f7.js')
  }
})
